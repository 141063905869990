



























import { Component, Prop, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal
  }
})
export default class ModalFinishQuestion extends Vue {
  @Prop() message!: string;

  async sendExercise() {
    this.$emit('finish-exercise');
  }
}
