import { render, staticRenderFns } from "./ModalTimeFinishQuestion.vue?vue&type=template&id=2de0396a&"
import script from "./ModalTimeFinishQuestion.vue?vue&type=script&lang=ts&"
export * from "./ModalTimeFinishQuestion.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports